import {
    CREATE_BOOKING_REQUEST,
    CREATE_BOOKING_SUCCESS,
    CREATE_BOOKING_FAILURE,
    CUSTOMER_DETAILS_CHANGED,
    REQUEST_PAYMENT_PLAN_SUCCESS,
    REQUEST_PAYMENT_PLAN,
    REQUEST_PAYMENT_PLAN_FAILURE,
    PAYMENT_ERROR,
    CREATE_CHECKOUT_SESSION,
    APPLY_PROMO_CODE,
    APPLY_PROMO_CODE_SUCCESS,
    APPLY_PROMO_CODE_FAILURE,
    CHECKOUT_ADD_FLIGHTS,
    CHECKOUT_REMOVE_FLIGHTS,
    CHECKOUT_SET_CUSTOMER_COUNT,
    CHECKOUT_SET_PAYMENT_TYPE,
    CHECKOUT_ADD_ACCOMMODATION,
    CHECKOUT_TOUR_SET_ROOMS_SELECTED,
    SET_PACKAGE_SELECTED,
    CHECKOUT_PRICE_CHANGE,
    CHECKOUT_PRICE_CHANGE_ACCEPT,
    CHECKOUT_TOUR_CUSTOMER_COUNT_CONFIRMED,
} from './types';
import { logErrorWithCustomMessage } from '../../utils/sentry';

const newCheckoutSession = (tourId, departureId, productType) => ({
    type: CREATE_CHECKOUT_SESSION,
    departureId,
    tourId,
    productType,
});

export const newTourCheckoutSession = (tourId, departureId) =>
    newCheckoutSession(tourId, departureId, 'Tour');

export const newFlightsCheckoutSession = (tourId, departureId) =>
    newCheckoutSession(tourId, departureId, 'Package');

export const newAccommodationCheckoutSession = (accommodationId, productType) =>
    newCheckoutSession(null, null, 'Accommodation');

export const createBookingRequest = data => {
    return {
        type: CREATE_BOOKING_REQUEST,
        data,
    };
};

export const createBookingSuccess = (bookingReference, conversionId) => {
    return {
        type: CREATE_BOOKING_SUCCESS,
        bookingReference,
        conversionId,
    };
};

export const createBookingFailure = (error, message) => {
    logErrorWithCustomMessage(error, 'Create Booking Failure', {
        message,
    });
    return {
        type: CREATE_BOOKING_FAILURE,
        error,
        message,
    };
};

export const checkoutPriceChange = (
    oldPrice,
    newPrice,
    newPaymentPlans,
    bookingReference
) => {
    return {
        type: CHECKOUT_PRICE_CHANGE,
        oldPrice,
        newPrice,
        newPaymentPlans,
        bookingReference,
    };
};

export const checkoutPriceChangeAccepted = (oldPrice, newPrice) => {
    return {
        type: CHECKOUT_PRICE_CHANGE_ACCEPT,
    };
};

export const customerDetailsChanged = details => {
    return {
        type: CUSTOMER_DETAILS_CHANGED,
        details,
    };
};

export const paymentError = data => {
    return {
        type: PAYMENT_ERROR,
        data,
    };
};

export const requestPaymentPlan = checkoutSummary => {
    return {
        type: REQUEST_PAYMENT_PLAN,
        checkoutSummary,
    };
};

export const requestPaymentPlanSuccess = data => {
    return {
        type: REQUEST_PAYMENT_PLAN_SUCCESS,
        data,
    };
};

export const requestPaymentPlanFailure = (statusCode, errors) => {
    return {
        type: REQUEST_PAYMENT_PLAN_FAILURE,
        statusCode,
        errors,
    };
};

export const applyPromoCode = promoCode => {
    return {
        type: APPLY_PROMO_CODE,
        promoCode,
    };
};

export const applyPromoCodeSuccess = data => {
    return {
        type: APPLY_PROMO_CODE_SUCCESS,
        data,
    };
};

export const applyPromoCodeFailure = data => {
    return {
        type: APPLY_PROMO_CODE_FAILURE,
        data,
    };
};

export const addFlights = (flights, travellerCount) => ({
    type: CHECKOUT_ADD_FLIGHTS,
    flights,
    travellerCount,
});

export const removeFlights = () => ({
    type: CHECKOUT_REMOVE_FLIGHTS,
});

export const setCustomerCount = count => ({
    type: CHECKOUT_SET_CUSTOMER_COUNT,
    count,
});

export const setSelectedPaymentPlan = paymentPlan => ({
    type: CHECKOUT_SET_PAYMENT_TYPE,
    paymentPlan,
});

export const addAccommodation = accommodation => ({
    type: CHECKOUT_ADD_ACCOMMODATION,
    accommodation,
});

export const setRoomsSelected = (selectedRooms, allSelected) => {
    return {
        type: CHECKOUT_TOUR_SET_ROOMS_SELECTED,
        selectedRooms,
        allSelected,
    };
};

export const setCustomerCountConfirmed = isConfirmed => {
    return {
        type: CHECKOUT_TOUR_CUSTOMER_COUNT_CONFIRMED,
        isConfirmed,
    };
};

export const setPackageSelected = (
    packageData,
    travellerCount,
    paymentType,
    prices
) => ({
    type: SET_PACKAGE_SELECTED,
    travellerCount,
    packageData,
    paymentType,
    prices,
});
