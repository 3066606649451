enum InteractionItem {
    tourTextSearch = 'TourTextSearch',
    whenSelect = 'WhenSelect',
    whenSelectWholeMonth = 'WhenSelectWholeMonth',
    whenSelectSpecificDate = 'WhenSelectSpecificDate',
    specificDateSelectMonth = 'SpecificDateSelectMonth',
    monthSelect = 'MonthSelect',
    activitySelect = 'ActivitySelect',
    tourSearch = 'TourSearch',
    tourSearchTab = 'TourSearchTab',
    tailorMadeTab = 'TailorMadeTab',
    tailorMadeDestination = 'TailorMadeDestination',
    tailorMadeSubmit = 'TailorMadeSubmit',
    flightSearchTab = 'FlightSearchTab',
    flightDestination = 'FlightDestination',
    flightSubmit = 'FlightSubmit',
    feefoButton = 'FeefoButton',
    tourCard = 'TourCard',
    landingPageCard = 'LandingPageCard',
    enquireTodayButton = 'EnquireTodayButton',
    tailorMyAdventureButton = 'TailorMyAdventureButton',
    expandFaq = 'ExpandFaq',
    getInTouchButton = 'GetInTouchButton',
    specificPostCard = 'SpecificPostCard',
    seeAllPosts = 'SeeAllPosts',
    subscribeNowButton = 'SubscribeNowButton',
    age = 'Age',
    maxBudget = 'MaxBudget',
    duration = 'Duration',
    physicalRating = 'PhysicalRating',
    destination = 'Destination',
    roomType = 'RoomType',
    adventureStyle = 'AdventureStyle',
    travelExperience = 'TravelExperience',
    startsEnds = 'StartsEnds',
    tourType = 'TourType',
    guideStyle = 'GuideStyle',
    inclusions = 'Inclusions',
    sort = 'Sort',
    tourTitle = 'TourTitle',
    viewTourButton = 'ViewTourButton',
    image = 'Image',
    downloadBrochure = 'DownloadBrochure',
    favouriteTour = 'FavouriteTour',
    enquireNowButton = 'EnquireNowButton',
    phoneLink = 'PhoneLink',
    viewMoreButton = 'ViewMoreButton',
    searchResultsMetaData = 'SearchResultsMetaData',
    reviews = 'Reviews',
    priceButton = 'PriceButton',
    openGallery = 'OpenGallery',
    cycleGallery = 'CycleGallery',
    viewAllImages = 'ViewAllImages',
    viewSpecificImage = 'ViewSpecificImage',
    brochureDownload = 'BrochureDownload',
    enquireButton = 'EnquireButton',
    callButton = 'CallButton',
    deposit = 'Deposit',
    paymentPlan = 'PaymentPlan',
    refunds = 'Refunds',
    cancellation = 'Cancellation',
    ATOL = 'ATOL',
    expandAll = 'ExpandAll',
    expandDay = 'ExpandDay',
    openStaticMap = 'OpenStaticMap',
    clickDynamicMap = 'ClickDynamicMap',
    yearSelect = 'YearSelect',
    whosGoingButton = 'WhosGoingButton',
    holdSpaceButton = 'HoldSpaceButton',
    bookTourButton = 'BookTourButton',
    showAllReviews = 'ShowAllReviews',
    readMoreButton = 'ReadMoreButton',
    contactUs = 'ContactUs',
    addFlightsTab = 'AddFlightsTab',
    tourOnlyTab = 'TourOnlyTab',
    soldoutEnquiry = 'SoldoutEnquiry',
    navigationTab = 'NavigationTab',
    homeLink = 'HomeLink',
    siteLinks = 'SiteLinks',
    favourites = 'Favourites',
    myAccount = 'MyAccount',
    currencySelector = 'CurrencySelector',
    increasePax = 'IncreasePax',
    decreasePax = 'DecreasePax',
    confirmPaxCount = 'ConfirmPaxCount',
    assignPax = 'AssignPax',
    formFilled = 'FormFilled',
    holdSpace = 'HoldSpace',
    bookAndPay = 'BookAndPay',
    bookNow = 'BookNow',
    tourSummaryExpand = 'TourSummaryExpand',
    viewBrochure = 'ViewBrochure',
    abtaButton = 'AbtaButton',
    contactNumber = 'ContactNumber',
    dealCard = 'DealCard',
    regionCard = 'RegionCard',
    destinationCard = 'DestinationCard',
    seeAllTours = 'SeeAllTours',
    infoSection = 'InfoSection',
    activityCard = 'ActivityCard',
    seeAllButton = 'SeeAllButton',
    dealLink = 'DealLink',
    changePaymentPlanOption = 'ChangePaymentPlanOption',
    passengerCount = 'PassengerCount',
    date = 'Date',
    contactDetails = 'ContactDetails',
    submit = 'Submit',
    activity = 'Activity',
    budget = 'Budget',
    departureDate = 'DepartureDate',
    adults = 'Adults',
    children = 'Children',
    comments = 'Comments',
    addFlights = 'AddFlights',
    departureAirport = 'DepartureAirport',
    seatClass = 'SeatClass',
    paxDetailsForm = 'PaxDetailsForm',
    callBackTime = 'CallBackTime',
    videoAppointment = 'VideoAppointment',
    sendEnquiry = 'SendEnquiry',
    unknown = 'Unknown',
    closeButton = 'CloseButton',
    bookPackage = 'BookPackage',
    alternativeFlights = 'AlternativeFlights',
    outboundDepartureTime = 'OutboundDepartureTime',
    returnDepartureTime = 'ReturnDepartureTime',
    airportFilter = 'AirportFilter',
    airlineFilter = 'AirlineFilter',
    link = 'Link',
    featureFlag = 'FeatureFlag',
    promoCode = 'PromoCode',
    returnToCheckoutToast = 'ReturnToCheckoutToast',
    dismissReturnToCheckoutToast = 'dismissReturnToCheckoutToast',
    privateTourSearchTab = 'PrivateTourSearchTab',
}

const InteractionItemMinifiedMap: { [key in InteractionItem]: string } = {
    [InteractionItem.tourTextSearch]: 'TTS',
    [InteractionItem.whenSelect]: 'WS',
    [InteractionItem.whenSelectWholeMonth]: 'WSWM',
    [InteractionItem.whenSelectSpecificDate]: 'WSSD',
    [InteractionItem.specificDateSelectMonth]: 'SDSM',
    [InteractionItem.monthSelect]: 'MS',
    [InteractionItem.activitySelect]: 'AS',
    [InteractionItem.tourSearch]: 'TS',
    [InteractionItem.tourSearchTab]: 'TST',
    [InteractionItem.tailorMadeTab]: 'TMT',
    [InteractionItem.tailorMadeDestination]: 'TMD',
    [InteractionItem.tailorMadeSubmit]: 'TMS',
    [InteractionItem.flightSearchTab]: 'FST',
    [InteractionItem.flightDestination]: 'FD',
    [InteractionItem.flightSubmit]: 'FS',
    [InteractionItem.feefoButton]: 'FB',
    [InteractionItem.tourCard]: 'TC',
    [InteractionItem.landingPageCard]: 'LPC',
    [InteractionItem.enquireTodayButton]: 'ETB',
    [InteractionItem.tailorMyAdventureButton]: 'TMAB',
    [InteractionItem.expandFaq]: 'EF',
    [InteractionItem.getInTouchButton]: 'GITB',
    [InteractionItem.specificPostCard]: 'SPC',
    [InteractionItem.seeAllPosts]: 'SAP',
    [InteractionItem.subscribeNowButton]: 'SNB',
    [InteractionItem.age]: 'AGE',
    [InteractionItem.maxBudget]: 'BUDG',
    [InteractionItem.duration]: 'DUR',
    [InteractionItem.physicalRating]: 'PHYS',
    [InteractionItem.destination]: 'DEST',
    [InteractionItem.roomType]: 'RT',
    [InteractionItem.adventureStyle]: 'AS',
    [InteractionItem.travelExperience]: 'TE',
    [InteractionItem.startsEnds]: 'SE',
    [InteractionItem.tourType]: 'TT',
    [InteractionItem.guideStyle]: 'GS',
    [InteractionItem.inclusions]: 'I',
    [InteractionItem.sort]: 'S',
    [InteractionItem.tourTitle]: 'TT',
    [InteractionItem.viewTourButton]: 'VTB',
    [InteractionItem.image]: 'I',
    [InteractionItem.downloadBrochure]: 'DB',
    [InteractionItem.favouriteTour]: 'FT',
    [InteractionItem.enquireNowButton]: 'ENB',
    [InteractionItem.phoneLink]: 'PL',
    [InteractionItem.viewMoreButton]: 'VMB',
    [InteractionItem.searchResultsMetaData]: 'SRM',
    [InteractionItem.reviews]: 'R',
    [InteractionItem.priceButton]: 'PB',
    [InteractionItem.openGallery]: 'OG',
    [InteractionItem.cycleGallery]: 'CG',
    [InteractionItem.viewAllImages]: 'VAI',
    [InteractionItem.viewSpecificImage]: 'VSI',
    [InteractionItem.brochureDownload]: 'BD',
    [InteractionItem.enquireButton]: 'EB',
    [InteractionItem.callButton]: 'CB',
    [InteractionItem.deposit]: 'D',
    [InteractionItem.paymentPlan]: 'PP',
    [InteractionItem.refunds]: 'RF',
    [InteractionItem.cancellation]: 'C',
    [InteractionItem.ATOL]: 'ATOL',
    [InteractionItem.expandAll]: 'EA',
    [InteractionItem.expandDay]: 'ED',
    [InteractionItem.openStaticMap]: 'OSM',
    [InteractionItem.clickDynamicMap]: 'CDM',
    [InteractionItem.yearSelect]: 'YS',
    [InteractionItem.whosGoingButton]: 'WGB',
    [InteractionItem.holdSpaceButton]: 'HSB',
    [InteractionItem.bookTourButton]: 'BTB',
    [InteractionItem.showAllReviews]: 'SAR',
    [InteractionItem.readMoreButton]: 'RMB',
    [InteractionItem.contactUs]: 'CU',
    [InteractionItem.addFlightsTab]: 'AFT',
    [InteractionItem.tourOnlyTab]: 'TOT',
    [InteractionItem.soldoutEnquiry]: 'SOLD',
    [InteractionItem.navigationTab]: 'NT',
    [InteractionItem.homeLink]: 'HL',
    [InteractionItem.siteLinks]: 'SL',
    [InteractionItem.favourites]: 'FV',
    [InteractionItem.myAccount]: 'MA',
    [InteractionItem.currencySelector]: 'CS',
    [InteractionItem.increasePax]: 'IP',
    [InteractionItem.decreasePax]: 'DP',
    [InteractionItem.confirmPaxCount]: 'CPAXC',
    [InteractionItem.assignPax]: 'AP',
    [InteractionItem.formFilled]: 'FF',
    [InteractionItem.holdSpace]: 'HS',
    [InteractionItem.bookAndPay]: 'BAP',
    [InteractionItem.bookNow]: 'BN',
    [InteractionItem.tourSummaryExpand]: 'TSE',
    [InteractionItem.viewBrochure]: 'VB',
    [InteractionItem.abtaButton]: 'AB',
    [InteractionItem.contactNumber]: 'CN',
    [InteractionItem.dealCard]: 'DC',
    [InteractionItem.activity]: 'A',
    [InteractionItem.regionCard]: 'RC',
    [InteractionItem.destinationCard]: 'DC',
    [InteractionItem.seeAllTours]: 'SAT',
    [InteractionItem.infoSection]: 'IS',
    [InteractionItem.activityCard]: 'AC',
    [InteractionItem.seeAllButton]: 'SAB',
    [InteractionItem.dealLink]: 'DL',
    [InteractionItem.changePaymentPlanOption]: 'CPPO',
    [InteractionItem.passengerCount]: 'PC',
    [InteractionItem.date]: 'DATE',
    [InteractionItem.contactDetails]: 'CD',
    [InteractionItem.submit]: 'SUB',
    [InteractionItem.budget]: 'BUD',
    [InteractionItem.departureDate]: 'DD',
    [InteractionItem.adults]: 'AD',
    [InteractionItem.children]: 'CHI',
    [InteractionItem.comments]: 'COM',
    [InteractionItem.addFlights]: 'AF',
    [InteractionItem.departureAirport]: 'DA',
    [InteractionItem.seatClass]: 'SC',
    [InteractionItem.paxDetailsForm]: 'PD',
    [InteractionItem.callBackTime]: 'CBT',
    [InteractionItem.videoAppointment]: 'VA',
    [InteractionItem.sendEnquiry]: 'SE',
    [InteractionItem.unknown]: 'Unk',
    [InteractionItem.closeButton]: 'CLB',
    [InteractionItem.bookPackage]: 'BP',
    [InteractionItem.alternativeFlights]: 'AF',
    [InteractionItem.outboundDepartureTime]: 'ODT',
    [InteractionItem.returnDepartureTime]: 'RDT',
    [InteractionItem.airportFilter]: 'APF',
    [InteractionItem.airlineFilter]: 'ALF',
    [InteractionItem.link]: 'L',
    [InteractionItem.featureFlag]: 'FF',
    [InteractionItem.promoCode]: 'PROMO',
    [InteractionItem.returnToCheckoutToast]: 'RTCT',
    [InteractionItem.dismissReturnToCheckoutToast]: 'DRTCT',
    [InteractionItem.privateTourSearchTab]: 'PTST',
};

export { InteractionItem, InteractionItemMinifiedMap };
