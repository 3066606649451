import { get } from './enduranceApi';
import { Feature } from '@/endurance/featureFlags';
import { FeatureFlag } from '../contexts/FeatureFlagContext';

const featureFlagUrl = `/featureFlags/featureFlag`;

export function getSpecificFeatures(
    features: FeatureFlag[],
    sessionId: string,
    accessToken: string | undefined = '',
    isBot: boolean
) {
    const url = `${featureFlagUrl}/specific?&sessionId=${sessionId}&cognitoToken=${accessToken}&isBot=${isBot}&features=${features.join(
        ','
    )}`;

    return get<Feature[]>(url);
}

const featureFlagApi = {
    getSpecificFeatures,
};

export default featureFlagApi;
